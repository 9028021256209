import { createSlice } from '@reduxjs/toolkit'
import { parseErrorFromReduxAction } from '../../domain/error/errorParser'
import { head, last } from '../../utils/array'
import { merge } from '../../utils/object'
import { STEP_IDS } from './itinerary.constants'
import { getFirstRouteIdForProvider, getProviderForRouteId, getProviderMode } from './itinerary.utils'
import {
  getStepsInReverseOrder,
  getStepsWithIdxStepPopulate,
  getStepsWithOneMoreStop,
  getStepsWithoutIdxStop,
  resetProviderOptionalOrSimplifiedStatus
} from './itinerarySlice.utils'

export const initialState = {
  activeSort: undefined,
  colors: undefined,
  computedCount: 0,
  currentMode: undefined,
  currentProvider: undefined,
  currentRouteId: 0,
  error: undefined,
  idx: undefined,
  isComputingDone: true,
  isRoadbookExpired: false,
  poisOnRoute: undefined,
  providers: [],
  roadbook: undefined,
  roadbookError: undefined,
  roadbookTimestamp: undefined,
  routeError: undefined,
  routes: [],
  steps: [{ ui: { stepId: STEP_IDS.departure } }, { ui: { stepId: STEP_IDS.arrival } }],
  tcAvailabilityInfo: undefined
}

const itinerarySlice = createSlice({
  name: 'itinerary',
  initialState,
  reducers: {
    resetItinerary: (state, action) => {
      return { ...initialState, computedCount: state.computedCount }
    },
    resetItineraryExceptSteps: (state, action) => {
      return { ...initialState, computedCount: state.computedCount, steps: state.steps }
    },
    setStepIdx: (state, action) => {
      state.idx = action?.payload
    },
    addStep: (state, action) => {
      state.steps = getStepsWithOneMoreStop(state.steps)
    },
    removeStep: (state, action) => {
      state.steps = getStepsWithoutIdxStop(state.steps, action.payload)
    },
    invertSteps: (state, action) => {
      state.steps = getStepsInReverseOrder(state.steps, action.payload)
    },
    setStepLocation: (state, action) => {
      const { location } = action.payload
      state.steps = getStepsWithIdxStepPopulate(state.steps, location, state.idx)
      state.idx = undefined
    },
    setStepResolvedLocation: (state, action) => {
      const { location } = action.payload
      state.steps = getStepsWithIdxStepPopulate(state.steps, location, state.idx)
      state.idx = undefined
    },
    setCurrentSteps: (state, action) => {
      const { fromAddress, from, toAddress, to } = action.payload
      state.steps = merge(initialState.steps, [
        fromAddress || (from ? { label: from } : head(state.steps)),
        toAddress || (to ? { label: to } : last(state.steps))
      ])
    },
    setCurrentMode: (state, action) => {
      state.currentMode = action.payload
    },
    setCurrentProvider: (state, action) => {
      const provider = action.payload
      if (state.currentProvider === provider) return
      state.currentMode = getProviderMode({ providers: state.providers, currentProvider: provider })
      state.currentProvider = provider
      state.currentRouteId = getFirstRouteIdForProvider(provider, state.routes)
    },
    setCurrentRoute: (state, action) => {
      const routeId = action.payload
      if (state.currentRouteId === routeId) return
      const currentProvider = getProviderForRouteId(routeId, state.routes)
      state.currentMode = getProviderMode({ providers: state.providers, currentProvider })
      state.currentProvider = currentProvider
      state.currentRouteId = routeId
    },
    setProviders: (state, action) => {
      state.routes = initialState.routes
      state.providers = action.payload
    },
    setProvidersColors: (state, action) => {
      state.colors = action.payload
    },
    addRoutes: (state, action) => {
      state.routes = [...(state.routes ?? []), ...action.payload]
    },
    routeNotFoundError: (state, action) => {
      const { mode, provider, error } = action.payload
      state.routeError = {
        ...state.routeError,
        [mode.id]: {
          errorMessage: error.message,
          origErrorMessage: error,
          provider
        }
      }
    },
    setItineraryAvailabilityInfo: (state, action) => {
      state.tcAvailabilityInfo = action.payload
    },
    dropPreviousProviderRoutes: (state, action) => {
      state.routes = state.routes.filter(route => route.provider.id !== action.payload)
    },
    removeProviderSimpliedOrOptionalStatus: (state, action) => {
      state.providers = resetProviderOptionalOrSimplifiedStatus(state.providers, action.payload)
    },
    setItineraryIsComputing: (state, action) => {
      state.routes = initialState.routes
      state.routeError = initialState.routeError
      state.error = initialState.error
      state.isComputingDone = false
    },
    setItineraryIsRecomputing: (state, action) => {
      state.routes = initialState.routes
      state.routeError = initialState.routeError
      state.error = initialState.error
      state.isComputingDone = false
    },
    setItineraryIsComplete: (state, action) => {
      state.isComputingDone = true
      state.computedCount = state.computedCount + 1
    },
    setProviderIsComputing: (state, action) => {
      state.isComputingDone = false
    },
    setProviderIsComplete: (state, action) => {
      state.isComputingDone = true
    },
    routeApiUnknownError: (state, action) => {
      state.error = parseErrorFromReduxAction(action)
    },
    setActiveSort: (state, action) => {
      state.activeSort = action.payload
    },
    resetActiveSort: (state, action) => {
      state.activeSort = initialState.activeSort
    },
    setPoisOnRoute: (state, action) => {
      const { type, label, id, rubric } = action.payload
      state.poisOnRoute = { type, id, label, ...(rubric && { rubric }) }
    },
    resetPoisOnRoute: (state, action) => {
      state.poisOnRoute = initialState.poisOnRoute
    },
    setCurrentRoadbook: (state, action) => {
      state.roadbook = action.payload
      state.isRoadbookExpired = false
    },
    resetRoadbook: (state, action) => {
      state.roadbook = initialState.roadbook
    },
    setRoadbookTimestamp: (state, action) => {
      state.roadbookTimestamp = Date.now()
    },
    setRoadbookExpired: (state, action) => {
      state.isRoadbookExpired = true
    },
    setRoadbookError: (state, action) => {
      state.roadbookError = action.payload
    }
  }
})

export const {
  resetItinerary,
  resetItineraryExceptSteps,
  setStepIdx,
  addStep,
  removeStep,
  invertSteps,
  setStepLocation,
  setStepResolvedLocation,
  setCurrentSteps,
  setCurrentMode,
  setCurrentProvider,
  setCurrentRoute,
  setProviders,
  setProvidersColors,
  addRoutes,
  routeNotFoundError,
  dropPreviousProviderRoutes,
  removeProviderSimpliedOrOptionalStatus,
  setItineraryIsComputing,
  setItineraryIsRecomputing,
  setItineraryIsComplete,
  setProviderIsComputing,
  setProviderIsComplete,
  routeApiUnknownError,
  setActiveSort,
  resetActiveSort,
  setPoisOnRoute,
  resetPoisOnRoute,
  setCurrentRoadbook,
  resetRoadbook,
  setRoadbookTimestamp,
  setRoadbookExpired,
  setRoadbookError,
  setItineraryAvailabilityInfo
} = itinerarySlice.actions

export default itinerarySlice
